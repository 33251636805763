import React from 'react';
import { useLocation } from 'react-router-dom';
var useQuery = function () {
    var _a, _b;
    var search = useLocation().search;
    var params = React.useMemo(function () { return new URLSearchParams(search); }, [search]);
    var searchQuery = {
        query: (_a = params.get('q')) !== null && _a !== void 0 ? _a : '',
        page: parseInt((_b = params.get('p')) !== null && _b !== void 0 ? _b : '1'),
    };
    return { params: params, searchQuery: searchQuery };
};
export default useQuery;
