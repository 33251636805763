var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext } from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText, styled, } from '@mui/material';
import { SearchContext } from '@local/src/Utils/Context';
var SearchSuggestionColumns = styled(List)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[theme.breakpoints.up('md')] = {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(5),
        },
        _b);
});
var SearchSuggestionList = function (_a) {
    var _b;
    var suggestion = _a.suggestion;
    var _c = useContext(SearchContext), setSearchValue = _c.setSearchValue, performSearch = _c.performSearch;
    var columns = (function () {
        var columns = [];
        var columnSize = 5;
        for (var i = 0; i < suggestion.options.length; i += columnSize) {
            columns.push(suggestion.options.slice(i, i + columnSize));
        }
        return columns;
    })();
    var onSuggestionClick = useCallback(function (value) {
        setSearchValue(value);
        performSearch(value);
    }, [setSearchValue, performSearch]);
    if (((_b = suggestion.options) === null || _b === void 0 ? void 0 : _b.length) === 0) {
        return null;
    }
    return (_jsxs(Box, { children: [_jsx(Divider, { sx: { my: 4 } }), _jsx(Typography, __assign({ variant: "h6", component: "h2" }, { children: suggestion.category })), _jsx(SearchSuggestionColumns, { children: columns.map(function (column, index) { return (_jsx(Box, __assign({ "data-testid": "suggestion-column" }, { children: column.map(function (option) { return (_jsx(ListItem, { children: _jsx(ListItemButton, __assign({ onClick: function () { return onSuggestionClick(option); }, role: "link", "aria-label": "S\u00F6kf\u00F6rslag: ".concat(option), sx: {
                                color: 'primary.main',
                                '&:hover, :focus': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'transparent',
                                },
                            } }, { children: _jsx(ListItemText, { children: option }) })) }, option)); }) }), "".concat(suggestion.category, "-column-").concat(index))); }) })] }));
};
export default SearchSuggestionList;
