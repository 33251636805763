import { createApi } from '@reduxjs/toolkit/query/react/';
import { trrFetchBaseQuery } from '@local/Utils/Network';
import { PAGE_SIZE } from '@local/Utils/constants';
import { replaceQuotationmarks } from '@local/Utils/Helpers/helpers';
var DEFAULT_ORDER = 'relevant';
var toQueryString = function (searchQuery) {
    var _a;
    var urlParams = new URLSearchParams({
        query: replaceQuotationmarks(searchQuery.query),
        skip: ((searchQuery.page - 1) * PAGE_SIZE).toString(),
        take: PAGE_SIZE.toString(),
        order: (_a = searchQuery.order) !== null && _a !== void 0 ? _a : DEFAULT_ORDER,
    });
    return urlParams.toString();
};
export var searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: trrFetchBaseQuery(),
    keepUnusedDataFor: 0,
    endpoints: function (builder) { return ({
        getSearchResult: builder.query({
            query: function (searchQuery) { return ({
                url: "search/content/pages?".concat(toQueryString(searchQuery)),
            }); },
        }),
    }); },
});
export var useGetSearchResultQuery = searchApi.useGetSearchResultQuery;
