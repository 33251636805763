var _a;
import { searchApi } from '@local/Components/SearchResults/services/searchResultsService';
import { searchSuggestionsApi } from '@local/Components/SearchSuggestions/services/searchSuggestionsService';
import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
var rootReducer = combineReducers((_a = {},
    _a[searchApi.reducerPath] = searchApi.reducer,
    _a[searchSuggestionsApi.reducerPath] = searchSuggestionsApi.reducer,
    _a));
export var setupStore = function (preloadedState) {
    return configureStore({
        reducer: rootReducer,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware().concat(searchApi.middleware, searchSuggestionsApi.middleware);
        },
        preloadedState: preloadedState,
    });
};
setupListeners(setupStore().dispatch);
var store = setupStore();
export default store;
