export var replaceQuotationmarks = function (query) {
    return query.replace('“', '"').replace('”', '"');
};
var pullRequestShareRegex = new RegExp('(\\/share\\/([a-z-]+)\\/(\\d{4,}))(\\/|$)', 'i');
var isSharePrUrl = function (pathname) { return pullRequestShareRegex.test(pathname); };
var getPrPrefix = function () {
    return pullRequestShareRegex.exec(window.location.pathname)[1];
};
export var addPRPrefix = function (url) {
    if (isSharePrUrl(window.location.pathname)) {
        return "".concat(getPrPrefix()).concat(url);
    }
    return url;
};
