var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
// This configuration will be used for local development
var compiletimeConfiguration = {
    AUTHORITY_URL: (_a = process.env.AUTHORITY_URL) !== null && _a !== void 0 ? _a : '',
    PUBLIC_WEB_API_URL: (_b = process.env.PUBLIC_WEB_API_URL) !== null && _b !== void 0 ? _b : '',
};
// This is the object that will be replaced in the release pipeline, do not change this
var APP_CONFIGURATION = {
    REPLACEABLE: 'CONFIG',
};
var getConfig = function () { return (__assign(__assign({}, compiletimeConfiguration), APP_CONFIGURATION)); };
export default getConfig;
