import { createApi } from '@reduxjs/toolkit/query/react/';
import { trrFetchBaseQuery } from '@local/Utils/Network';
export var searchSuggestionsApi = createApi({
    reducerPath: 'searchSuggestionsApi',
    baseQuery: trrFetchBaseQuery(),
    keepUnusedDataFor: 0,
    endpoints: function (builder) { return ({
        getSearchSuggestions: builder.query({
            query: function () { return ({
                url: '/search/suggestions',
            }); },
        }),
    }); },
});
export var useGetSearchSuggestionsQuery = searchSuggestionsApi.useGetSearchSuggestionsQuery;
