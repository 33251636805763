import { useContext } from 'react';
import { Context } from './Context';
var replaceVariables = function (text, variables) {
    if (variables === void 0) { variables = {}; }
    var result = text;
    Object.keys(variables).forEach(function (key) {
        var regex = new RegExp("{{".concat(key, "}}"), 'g');
        result = result.replace(regex, variables[key]);
    });
    return result;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useEpiString = function () {
    var _a;
    var values = (_a = useContext(Context)) !== null && _a !== void 0 ? _a : {};
    return function (key, variables) {
        return values[key] ? replaceVariables(values[key], variables) : key;
    };
};
