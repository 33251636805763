var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography } from '@mui/material';
import EmptyIllustration from './EmptyIllustration';
var EmptyResult = function (_a) {
    var heading = _a.heading, description = _a.description;
    return (_jsxs(Box, __assign({ textAlign: "center", sx: {
            svg: {
                maxWidth: '400px',
                py: 5,
            },
        } }, { children: [_jsx(EmptyIllustration, { "aria-hidden": true }), _jsx(Typography, __assign({ variant: "h2" }, { children: heading })), _jsx(Typography, __assign({ variant: "preamble" }, { children: description }))] })));
};
export default EmptyResult;
