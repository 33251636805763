var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useEffect, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@local/src/Utils/Hooks';
export var SearchContext = createContext(null);
export var SearchContextProvider = function (_a) {
    var children = _a.children;
    var searchQuery = useQuery().searchQuery;
    var history = useHistory();
    var _b = useState(searchQuery.query), searchValue = _b[0], setSearchValue = _b[1];
    useEffect(function () {
        if (!searchQuery.query) {
            setSearchValue('');
        }
    }, [searchQuery.query, setSearchValue]);
    var performSearch = useCallback(function (manualValue) {
        if (manualValue || searchValue) {
            history.push({
                search: new URLSearchParams({
                    q: manualValue !== null && manualValue !== void 0 ? manualValue : searchValue,
                }).toString(),
            });
        }
    }, [history, searchValue]);
    return (_jsx(SearchContext.Provider, __assign({ value: {
            setSearchValue: setSearchValue,
            searchValue: searchValue,
            performSearch: performSearch,
        } }, { children: children })));
};
