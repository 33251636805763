import React from 'react';
export var AppShellRouter = function (_a) {
    var currentKey = _a.currentKey, children = _a.children;
    var component = null;
    React.Children.forEach(children, function (child) {
        if (React.isValidElement(child)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (child.props.urlKey === currentKey) {
                component = child;
            }
        }
        else {
            component = null;
        }
    });
    return component;
};
