var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, List, ListItem, ListItemText, styled, Typography, } from '@mui/material';
import { useEpiString } from '@local/Utils/Hooks/useEpiStrings';
import EmptyResult from '@local/Components/NoSearchResults/EmptyResult';
var BodyWrapper = styled(Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3)
        },
        _b[theme.breakpoints.up('md')] = {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: theme.spacing(5),
        },
        _b);
});
var HighlightBox = styled(Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            borderRadius: '4px',
            padding: theme.spacing(3)
        },
        _b[theme.breakpoints.up('md')] = {
            width: '360px',
            minHeight: '220px',
        },
        _b);
});
var NoSearchResult = function (_a) {
    var query = _a.query, tips = _a.tips, personal = _a.personal;
    var epi = useEpiString();
    var tipList = [];
    if (tips) {
        for (var i in tips) {
            if (i.startsWith('tip.')) {
                tipList.push({ key: i, value: tips[i] });
            }
        }
    }
    var preamble = query
        ? epi('preamble.query', { query: query })
        : epi('preamble.noQuery');
    return (_jsxs(Box, { children: [_jsx(Box, __assign({ mt: 9, mb: 5, sx: { breakWord: 'word-break' } }, { children: _jsx(EmptyResult, { heading: epi('heading'), description: preamble }) })), query && (_jsxs(BodyWrapper, { children: [tips && tipList.length > 0 && (_jsxs(HighlightBox, __assign({ bgcolor: "surface.grey" }, { children: [_jsx(Typography, __assign({ variant: "h5", component: "h3", mb: 2, "data-testid": "no-search-result-tips-heading" }, { children: tips['heading'] })), _jsx(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    ml: 2,
                                    p: 0,
                                } }, { children: tipList.map(function (tip) { return (_jsx(ListItem, __assign({ "data-testid": "no-search-result-tips-".concat(tip.key), sx: { display: 'list-item' } }, { children: _jsx(ListItemText, { children: tip.value }) }), tip.key)); }) }))] }))), personal && (_jsxs(HighlightBox, __assign({ bgcolor: "surface.orange" }, { children: [_jsx(Typography, __assign({ variant: "h5", component: "h3", mb: 2, "data-testid": "no-search-result-personal-heading" }, { children: personal['heading'] })), _jsx(Typography, __assign({ variant: "body1", "data-testid": "no-search-result-personal-body" }, { children: personal['body'] }))] })))] }))] }));
};
export default NoSearchResult;
