import getConfig from '@local/Utils/ConfigService/getConfig';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/';
import { getIdToken } from '@trr/app-shell-data';
export var PUBLIC_WEB_API_URL = getConfig().PUBLIC_WEB_API_URL;
export var trrFetchBaseQuery = function () {
    return fetchBaseQuery({
        baseUrl: "".concat(PUBLIC_WEB_API_URL),
        prepareHeaders: function (headers) {
            headers.set('Accept-Language', 'sv');
            getIdToken() && headers.set('authorization', "Bearer ".concat(getIdToken()));
            return headers;
        },
    });
};
