var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Link, List, ListItem, ListItemButton, ListItemText, Pagination, PaginationItem, styled, Typography, } from '@mui/material';
import { EpiStringProvider } from '@local/Utils/Hooks/useEpiStrings';
import { PAGE_SIZE } from '@local/src/Utils/constants';
import NoSearchResult from '@local/Components/NoSearchResults';
import { useQuery } from '@local/src/Utils/Hooks';
import { useGetSearchResultQuery } from './services/searchResultsService';
var StyledListItem = styled(ListItem)(function (_a) {
    var theme = _a.theme;
    return ({
        borderBottom: "1px solid ".concat(theme.palette.divider),
        '&:first-of-type': {
            borderTop: "1px solid ".concat(theme.palette.divider),
        },
    });
});
export var SearchResults = function (_a) {
    var noSearchResultContent = _a.noSearchResultContent;
    var location = useLocation();
    var searchQuery = useQuery().searchQuery;
    var _b = useGetSearchResultQuery(searchQuery), searchResults = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching, isError = _b.isError;
    if (isFetching || isLoading) {
        return (_jsx(Box, __assign({ sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minHeight: '180px',
            } }, { children: _jsx(CircularProgress, {}) })));
    }
    if (isError || !searchResults || searchResults.results.length === 0) {
        return (_jsx(EpiStringProvider, __assign({ value: noSearchResultContent.noSearchResult }, { children: _jsx(NoSearchResult, { query: searchQuery.query, personal: noSearchResultContent.noSearchResultPersonal, tips: noSearchResultContent.noSearchResultTips }) })));
    }
    return (_jsxs(Box, __assign({ maxWidth: "800px" }, { children: [_jsx(Box, __assign({ my: 3 }, { children: _jsxs(Typography, __assign({ component: "span", variant: "body2" }, { children: [searchResults.count, " tr\u00E4ffar p\u00E5 ", _jsx("b", { children: searchQuery.query })] })) })), _jsx(List, __assign({ "aria-label": "S\u00F6kresultat", sx: { p: 0 } }, { children: searchResults.results.map(function (result, index) { return (_jsx(StyledListItem, { children: _jsx(ListItemButton, __assign({ component: Link, href: result.url, sx: {
                            px: 2,
                            py: 3,
                            flexDirection: 'column',
                            alignItems: 'start',
                        } }, { children: _jsx(ListItemText, { primary: _jsx(Typography, __assign({ component: "p", variant: "h5", color: "text.primary", mb: 2, "data-testid": "search-result-title-".concat(index) }, { children: result.title })), secondary: _jsx(Typography, __assign({ "aria-hidden": "true", component: "span", variant: "body1", color: "text.primary", "data-testid": "search-result-description-".concat(index) }, { children: result.description || 'Beskrivning saknas' })), sx: { m: 0 } }) })) }, result.url)); }) })), _jsx(Pagination, { page: searchQuery.page, count: Math.ceil(searchResults.count / PAGE_SIZE), renderItem: function (item) { return (_jsx(PaginationItem, __assign({ component: Link, href: "".concat(location.pathname, "?q=").concat(searchQuery.query).concat(item.page === 1 ? '' : '&p=' + item.page), "data-testid": "pagination-button" }, item))); }, size: "large", sx: { mt: 2 } })] })));
};
export default SearchResults;
